import { meta } from "react-dom-factories"

interface IApiImageMetadataV1 {
  Width?: number
  Height?: number
  LqipBase64?: string
  LqipType?: string
  ID: string
}

export interface IApiLocation {
  lat: number
  lon: number
}

interface ICommonApiAd {
  Title: string
  Description: string
  CategoryName: string
  AnimalTypeName: string
  CreatedAt: string
  InListViewsCount: number
  ClicksCount: number
  ID: number
  Source: string
  Location: IApiLocation
  Name: string
  Gender: string
  Color: string
  Signs: string
  Reward: string
  Phone: string
  Breed: string
  PlaceDescription: string
  NearestPlaceName: string
  FinishedAt?: string
  FinishedReason?: string
}

export interface IApiAd extends ICommonApiAd {
  ImagesMetadataV1: IApiImageMetadataV1[]
}

export interface IModifyApiImage {
  WasAlreadyUploadedToServer: boolean

  ID?: string // ID is set if WasAlreadyUploadedToServer is true
  File?: File // File is set if WasAlreadyUploadedToServer is false
}

export interface IModifyApiAd extends ICommonApiAd {
  MainImage?: IModifyApiImage
  AdditionalImages: IModifyApiImage[]
}

export const makeModifyApiAd = (ad: IApiAd): IModifyApiAd => {
  const ret = ad as any as IModifyApiAd
  const metadata = ad.ImagesMetadataV1 || []
  ret.MainImage = metadata.length ? { WasAlreadyUploadedToServer: true, ID: metadata[0].ID } : null
  ret.AdditionalImages = metadata.length > 1 ? metadata.slice(1).map((m) => ({ WasAlreadyUploadedToServer: true, ID: m.ID })) : []
  return ret
}
