import React from "react"
import { connect } from "react-redux"
import { RouteComponentProps } from "react-router"
import { push, Push } from "connected-react-router"
import { IAppStore } from "reducers"
import { toggle } from "modules/toggle"
import LogoSvg from "assets/logo.svg"
import Link, { ILinkProps } from "components/lib/Link"

import { Box, Heading, Flex, Button } from "@chakra-ui/core"

import styled from "modules/utils/theme"

const NavLink = styled(Link)`
  box-shadow: none;
  color: ${(p) => p.theme.colors.white};
  &:hover {
    box-shadow: ${(p) => `0 1px 0 0 ${p.theme.colors.white}`};
  }
`

const LogoLink = styled(Link)`
  box-shadow: none;
  color: ${(p) => p.theme.colors.white};
  &:hover {
    color: ${(p) => p.theme.colors.orange[400]};
  }
`

const NavItem = (linkProps: ILinkProps) => <NavLink mr={6} mt={{ base: 4, lg: 0 }} display="block" {...linkProps} />

interface IHeaderStateProps {
  createdAdId?: number
  needShowMenu: boolean
}

interface IDispatchProps {
  toggle(name: string, value?: boolean): void
  push: Push
}

interface IHeaderProps extends IHeaderStateProps, IDispatchProps, RouteComponentProps<void> {}

const toggleKey = `header burger`

const StyledLogoSvg = styled(LogoSvg)`
  height: ${(p) => p.theme.fontSizes[`5xl`]};
  width: auto;
  margin-right: ${(p) => p.theme.space[2]};
  display: inline-block;
`

const Header = (props: IHeaderProps) => {
  return (
    <Flex as="nav" align="center" justify="space-between" wrap="wrap" padding={2} bg="teal.500" color="white" {...props}>
      <Flex align="center" mr={5}>
        <Heading as="span" size="lg" letterSpacing={"-.1rem"}>
          <LogoLink href="/">
            <StyledLogoSvg />
            FoundPets.Ru
          </LogoLink>
        </Heading>
      </Flex>

      <Box display={{ base: "block", lg: "none" }} onClick={() => props.toggle(toggleKey)}>
        <svg fill="white" width="12px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <title>Меню</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>

      <Box
        display={{ base: props.needShowMenu ? "block" : "none", lg: "flex" }}
        width={{ base: "full", lg: "auto" }}
        alignItems="center"
        flexGrow={1}
      >
        <NavItem href="/Все города/Пропала">Пропавшие животные</NavItem>
        <NavItem href="/Все города/Найдена">Найденные животные</NavItem>
        <NavItem href="/Все города?finished=yes">Завершенные поиски</NavItem>
        <NavItem href="/feedback">Идеи</NavItem>
      </Box>

      <Box display={{ base: props.needShowMenu ? "block" : "none", lg: "block" }} mt={{ base: 4, lg: 0 }}>
        <Button bg="transparent" border="1px" onClick={() => props.push(props.createdAdId ? `/ads/${props.createdAdId}` : `/ads/new`)}>
          {props.createdAdId ? `Мое объявление` : `Создать объявление`}
        </Button>
      </Box>
    </Flex>
  )
}

const mapStateToProps = (state: IAppStore): IHeaderStateProps => {
  return {
    createdAdId: state.ads.session.createdAdId,
    needShowMenu: state.toggle.store[toggleKey],
  }
}

const mapDispatchToProps = {
  toggle,
  push,
}

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(Header)
