import { IAdsStore, adsReducer } from "modules/ads/ads.reducers"
import placesReducer, { IPlacesStore } from "modules/places/places.reducers"
import resultReducer, { IResultStore } from "modules/result/result.reducers"
import { IToggleStore, toggleReducer } from "modules/toggle"
import { rtbReducer, IRtbStore } from "modules/rtb"
import { connectRouter, RouterState } from "connected-react-router"
import { reducer as formReducer, FormStateMap } from "redux-form"
import { IModule } from "redux-dynamic-modules"
import { History } from "history"

export interface IAppStore {
  ads?: IAdsStore
  places?: IPlacesStore
  result?: IResultStore
  toggle?: IToggleStore
  rtb?: IRtbStore

  router?: RouterState<{}>
  form?: FormStateMap
}

export const createBaseReduxModule = (history: History<{}>): IModule<IAppStore> => {
  return {
    id: "base_root",
    reducerMap: {
      ads: adsReducer,
      places: placesReducer,
      result: resultReducer,
      toggle: toggleReducer,
      rtb: rtbReducer,

      router: connectRouter(history),
      form: formReducer,
    },
  }
}
