import { combineReducers } from "redux"
import { PlaceActions } from "./places.actions"

export interface IPlacesStore {
  near?: string[]
}

const near = (state: string[] = null, action: any): string[] => {
  switch (action.type) {
    case PlaceActions.NearFetched:
      return action.nearPlaces
    default:
      return state
  }
}

export default combineReducers<IPlacesStore>({
  near,
})
