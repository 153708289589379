import amplitude from "amplitude-js"

export const reachGoal = (category: string, action: string, label?: string, value?: any) => {
  if (typeof window === "undefined") {
    return
  }

  console.log('reachGoal: category="%s", action="%s", label="%s", value=%s', category, action, label, value)

  const w = window as any
  if (w.yaCounter42654659 !== undefined) {
    const yaGoalName = category + "." + action
    w.yaCounter42654659.reachGoal(yaGoalName, () => {
      console.log('yandex goal "%s" was sent', yaGoalName)
    })
  }

  if (w.ga !== undefined) {
    w.ga("send", {
      hitType: "event",
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
    })
    console.log("sent goal to google")
  }
}

let amplitudeClient: amplitude.AmplitudeClient = null

export const sendAmplitudeEvent = async (eventName: string, eventProps: object): Promise<void> => {
  if (__SERVER__) {
    throw new Error(`amplitude event ${eventName} with props ${JSON.stringify(eventProps)} shouldn't be sent on server-side`)
  }
  if (__DEV__) {
    console.info(`Sending amplitude event ${eventName}`, eventProps)
  }
  if (amplitudeClient === null) {
    amplitudeClient = amplitude.getInstance()
    const options: amplitude.Config = {
      // these options will be merged to default options, so it's safe to not duplicate default options
      includeUtm: true,
      includeReferrer: true,
      includeGclid: true,
      includeFbclid: true,
    }
    amplitudeClient.init(__DEV__ ? `b5b99a19358d71fc415c3bc090b1129f` : `85c7aa07e5669ae13e7a0baca2b7b168`, null, options)
  }

  // TODO: for tracking of fast-exiting users we need to send first event from node.js SSR before returning page to a user.
  return new Promise((resolve, reject) => {
    amplitudeClient.logEvent(eventName, eventProps, (responseCode: number, responseBody: string, details?: { reason: string }) => {
      if (responseCode === 200) {
        resolve(null)
      } else {
        reject({ responseCode, responseBody, details })
      }
    })
  })
}
