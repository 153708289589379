import React from "react"
import loadable from "@loadable/component"
import { Route, Switch } from "react-router-dom"
import Loader from "components/lib/Loader"
import App from "components/App"

const loadOpts = {
  fallback: <Loader />,
}

const Home = loadable(() => import(/* webpackPrefetch: true */ "../components/pages/Home"), loadOpts)
const AdsIndex = loadable(() => import(/* webpackPrefetch: true */ "../components/pages/AdsIndex"), loadOpts)
const AdDetail = loadable(() => import(/* webpackPrefetch: true */ "../components/pages/AdDetail"), loadOpts)
const Feedback = loadable(() => import("../components/pages/Feedback"), loadOpts)
const AdCreate = loadable(() => import("../components/pages/AdCreate"), loadOpts)
const AdEdit = loadable(() => import("../components/pages/AdEdit"), loadOpts)

export default () => (
  <App>
    <Switch>
      <Route exact path="/ads/new" component={AdCreate} />
      <Route exact path="/ads/:id([0-9]+)/edit" component={AdEdit} />

      <Route exact path="/ads/:id([0-9]+)" component={AdDetail} />

      <Route exact path="/feedback" component={Feedback} />

      <Route exact path="/" component={Home} />
      <Route exact path="/:place" component={AdsIndex} />
      <Route exact path="/:place/:category" component={AdsIndex} />
      <Route exact path="/:place/:category/:animalType" component={AdsIndex} />
    </Switch>
  </App>
)
