/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Link as ChakraLink, LinkProps as ChakraLinkProps, PseudoBoxProps } from "@chakra-ui/core"
import { ITheme } from "modules/utils/theme"

export interface ILinkProps extends ChakraLinkProps {
  renderedComponent?: React.FC<ChakraLinkProps>
}

const linkCss = (theme: ITheme) => ({
  color: theme.colors.link,
  textDecoration: `none`,
  boxShadow: `0 1px 0 0 ${theme.colors.link}`,
  [`&:hover`]: {
    textDecoration: `none`,
    boxShadow: `none`,
  },
})

type Props = ILinkProps & PseudoBoxProps & React.HTMLProps<HTMLAnchorElement>

const Link = ({ renderedComponent, href, isExternal, children, ...restProps }: Props) => {
  if (isExternal) {
    return (
      <a css={linkCss} target="_blank" href={href}>
        {children}
      </a>
    )
  }

  // TODO: fix this hack after https://github.com/chakra-ui/chakra-ui/issues/205 gets fixed
  const linkProps = { to: href, as: RouterLink } as any
  const LinkComponent = renderedComponent || ChakraLink
  return (
    <LinkComponent css={linkCss} {...restProps} {...linkProps}>
      {children}
    </LinkComponent>
  )
}

export default Link
