import React from "react"
import { Box, Text, Flex, Divider } from "@chakra-ui/core"

const Footer: React.StatelessComponent<any> = () => (
  <Box as="footer" padding={2}>
    <Divider />
    <Flex>
      <Text marginRight={2}>&copy; {new Date().getFullYear()}</Text>
      <Text marginRight={2}>|</Text>
      <Text>
        Связаться с нами: <a href="mailto:contact@foundpets.ru">contact@foundpets.ru</a>
      </Text>
    </Flex>
  </Box>
)

export default Footer
