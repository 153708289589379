import React from "react"
import { Helmet } from "react-helmet"
import { withRouter, RouteComponentProps } from "react-router-dom"
import loadable from "@loadable/component"

const Toastr = loadable(() => import("modules/toastr/component"))

import { Box, CSSReset } from "@chakra-ui/core"
import { IconContext } from "react-icons"

// Don't use @chakra-ui theme provider because it import theme
// which is fat because of large IconPaths.js. It will be fixed
// in a new chakra-ui.
import { ThemeProvider } from "emotion-theming"

import Header from "components/layout/Header"
import Footer from "components/layout/Footer"
import { theme } from "modules/utils/theme"
import { toggle } from "modules/toggle"
import { toggleKey } from "modules/toastr/toggle"
import { connect } from "react-redux"
import { IAppStore } from "reducers"

interface IStateProps {
  toastWasTriggered: boolean
}

interface IOwnProps {
  children: JSX.Element
}

interface IDispatchProps {
  toggle(name: string, value?: boolean): void
}

const App = (props: IOwnProps & IStateProps & IDispatchProps & RouteComponentProps<void>) => (
  <div>
    <Helmet>
      <meta property="og:locale" content="ru_RU" />
      <meta property="og:site_name" content="FoundPets.ru" />
      <meta property="og:url" content={`${HOST}${props.location.pathname}${props.location.search}`} />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>

    <ThemeProvider theme={theme}>
      <IconContext.Provider value={{ color: theme.colors.blue[400] }}>
        <CSSReset />
        <Header />
        <Box>{props.children}</Box>
        <Footer />
        {
          props.toastWasTriggered && <Toastr /> // lazy mount to be able to use code splitting
        }
      </IconContext.Provider>
    </ThemeProvider>
  </div>
)

const mapDispatchToProps = {
  toggle,
}

const mapStateToProps = (state: IAppStore): IStateProps => {
  return {
    toastWasTriggered: state.toggle.store[toggleKey],
  }
}

const routedApp = withRouter(App)

export default connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(routedApp)
