import { applyMiddleware, compose, Middleware } from "redux"
import { createStore, IModuleStore } from "redux-dynamic-modules"
import { routerMiddleware } from "connected-react-router"
import { createBaseReduxModule, IAppStore } from "../reducers"
import createSagaMiddleware, { END, Task } from "redux-saga"
import { createMemoryHistory, createBrowserHistory } from "history"

export const history = __SERVER__
  ? createMemoryHistory({
      initialEntries: [],
    })
  : createBrowserHistory()

export type ConfiguredStore = IModuleStore<IAppStore> & {
  runSaga: (saga: any) => Task
  close: () => void
}

const configureStore = async (initialState: IAppStore): Promise<ConfiguredStore> => {
  const sagaMiddleware = createSagaMiddleware()
  const middleware: Middleware[] = [sagaMiddleware, routerMiddleware(history)]
  if (__DEV__ && __CLIENT__) {
    const logger = await import("redux-logger")
    middleware.push(logger.logger)
  }
  const baseRootModule = createBaseReduxModule(history)
  const enhancer = compose(applyMiddleware(...middleware))
  const store: IModuleStore<IAppStore> = createStore(
    {
      initialState,
      enhancers: [enhancer],
    },
    baseRootModule
  )

  // https://github.com/microsoft/redux-dynamic-modules/issues/53
  // if (module.hot) {
  //   module.hot.accept("../reducers", () => {
  //     store.replaceReducer(baseRootModule)
  //   })
  // }

  const configuredStore = store as ConfiguredStore
  configuredStore.runSaga = sagaMiddleware.run
  configuredStore.close = () => store.dispatch(END)

  return configuredStore
}

export default configureStore
