import axios from "axios"
import Agent, { HttpsAgent } from "agentkeepalive"

export const makeApiGetRequest = (path: string, cookie?: string): Promise<IApiResponse> => {
  return makeApiRequest(path, "GET", cookie)
}
export const makeApiPostRequest = (path: string, data: any, cookie?: string): Promise<IApiResponse> => {
  return makeApiRequest(path, "POST", cookie, data)
}
export const makeApiPutRequest = (path: string, data: any, cookie?: string): Promise<IApiResponse> => {
  return makeApiRequest(path, "PUT", cookie, data)
}

const agentOptions = {
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000, // active socket keepalive for 60 seconds
  freeSocketTimeout: 30000, // free socket keepalive for 30 seconds
}

const httpsAgentOptions = { ...agentOptions, rejectUnauthorized: __DEV__ ? false : true }

const axiosInstance = axios.create({
  httpAgent: new Agent(agentOptions),
  httpsAgent: new HttpsAgent(httpsAgentOptions),
})

const makeApiRequest = (path: string, method: string, cookie?: string, data?: any): Promise<IApiResponse> => {
  const startTime = Date.now()
  const headers: any = {
    Accept: "application/json",
  }

  if (__SERVER__ && cookie) {
    headers.cookie = cookie
  }

  return axiosInstance
    .request<IApiResponse>({
      url: API_HOST + path,
      data,
      method,
      headers,
      withCredentials: true,
    })
    .then<IApiResponse>((resp) => {
      console.info("API: %s: %s: got response for %sms with cookie %s", method, path, Date.now() - startTime, cookie)
      return { data: resp.data }
    })
    .catch<IApiResponse>((error: any) => {
      console.error(
        `API: ${method}: ${API_HOST + path}: got error: ${error && error.message ? error.message : error} for ${Date.now() - startTime}ms`
      )
      return { error }
    })
}

export interface IApiResponse {
  error?: any
  data?: any
}
