import { combineReducers } from "redux"
import { LOCATION_CHANGE } from "connected-react-router"

export interface IRtbStore {
  pageNumber: number
}

const pageNumber = (state: number = 0, action: any): number => {
  switch (action.type) {
    case LOCATION_CHANGE:
      // XXX: don't rate-limit by 30s because in rules it's said that we can update only once per 30s
      // in one screen. https://yandex.ru/legal/partner/index.html
      return state + 1 // increment pageNumber to refresh rtb ads
    default:
      return state
  }
}

export const rtbReducer = combineReducers<IRtbStore>({
  pageNumber,
})
