import {
  AdActions,
  IAdsPageFetchAction,
  onAdsPageFetching,
  onAdsPageFetched,
  onAdsCountersFetched,
  onAdFetched,
  onAdCreated,
  onAdCreatedIDGot,
  onAdFetchedForEdition,
  onAdEditFailed,
  onAdEditStepChanged,
  onAdFinishComplete,
} from "./ads.actions"
import { toggle } from "modules/toggle"
import { toggleKey as toastToggleKey } from "modules/toastr/toggle"
import { IAdsPageParams, IAd, IImageMetadata } from "./ads.reducers"
import { put, takeEvery, call, select, fork, throttle, ForkEffect, takeLatest, delay } from "redux-saga/effects"
import axios from "axios"
import * as queryString from "query-string"
import { makeApiGetRequest, makeApiPostRequest, makeApiPutRequest, IApiResponse } from "../api"
import { notFound, internalError } from "../result/result.actions"
import { IAppStore } from "reducers"
import { push } from "connected-react-router"
import { reachGoal, sendAmplitudeEvent } from "../utils/events"
import { IApiAd, IModifyApiAd, IModifyApiImage } from "./api_ad"

const getUrl = (params: IAdsPageParams): string => {
  let u = "/"
  if (params.place) {
    u += encodeURIComponent(params.place)
  }
  if (params.category) {
    u += "/" + encodeURIComponent(params.category)
  }
  if (params.animalType) {
    u += "/" + encodeURIComponent(params.animalType)
  }

  return u
}

const pageParamsToUrlParams = (params: IAdsPageParams) => ({
  page: params.page === 1 ? undefined : params.page,
  search_radius: params.searchRadius === 20 ? undefined : params.searchRadius,
  q: params.query || undefined,
  finished: !params.finishedFilter || params.finishedFilter === `no` ? undefined : params.finishedFilter,
  source: params.sourceFilter || undefined,
})

export const getUrlWithParams = (params: IAdsPageParams): string => {
  const u = getUrl(params)
  const qs = queryString.stringify(pageParamsToUrlParams(params))
  return `${u}${qs !== "" ? "?" : ""}${qs}`
}

export const getUrlWithParamsWoPage = (params: IAdsPageParams): string => {
  const u = getUrl(params)
  const urlParams = pageParamsToUrlParams(params)
  urlParams.page = undefined
  const qs = queryString.stringify(urlParams)
  return `${u}${qs !== "" ? "?" : ""}${qs}`
}

function isNotFoundError(resp: IApiResponse): boolean {
  return resp && resp.error && resp.error.response && resp.error.response.status === 404
}

const getSearchApiUrl = (params: IAdsPageParams, state: IAppStore): string => {
  const qp = {
    pl: params.place === "Все города" ? null : params.place,
    at: params.animalType ? params.animalType : null,
    cat: params.category ? params.category : null,
    q: params.query ? params.query : null,
    sr: params.searchRadius,
    p: params.page,
    finished: params.finishedFilter,

    source: params.sourceFilter,
    nocache: state.ads.isNoCache ? "1" : undefined,
  }
  const qs = queryString.stringify(qp)
  return `/v1/posts/search${qs !== "" ? "?" : ""}${qs}`
}

const parseAd = (ad: IApiAd): IAd => {
  const imagesMetadata: IImageMetadata[] = (ad.ImagesMetadataV1 || []).map((imgData) => ({
    id: imgData.ID,
    width: imgData.Width,
    height: imgData.Height,
    lqipBase64: imgData.LqipBase64,
    lqipType: imgData.LqipType,
  }))

  const ret: IAd = {
    title: ad.Title,
    mainImage: imagesMetadata.length ? imagesMetadata[0] : null,
    additionalImages: imagesMetadata.length ? imagesMetadata.slice(1) : [],
    description: ad.Description,
    category: ad.CategoryName,
    animalType: ad.AnimalTypeName,
    createdAt: ad.CreatedAt,
    inListViewsCount: ad.InListViewsCount,
    viewsCount: ad.ClicksCount,
    id: ad.ID,
    source: ad.Source,
    location: ad.Location,
    name: ad.Name,
    gender: ad.Gender,
    color: ad.Color,
    signs: ad.Signs,
    reward: ad.Reward,
    phone: ad.Phone,
    breed: ad.Breed,
    placeDescription: ad.PlaceDescription,
    nearestPlaceName: ad.NearestPlaceName,
    finishedAt: ad.FinishedAt,
    finishedReason: ad.FinishedReason,
  }
  return ret
}

const getError = (resp: IApiResponse) => (isNotFoundError(resp) ? notFound() : internalError())

const errorTitle = "Ошибка"
const successTitle = "Ура!"

function* showToast(message: string) {
  yield call(reachGoal, "ERROR", "SHOW_TOAST")
  yield put(toggle(toastToggleKey, true)) // lazy-render toast component
  const { toastr } = yield import("react-redux-toastr")
  yield delay(1000) // time to lazy-load toast component, TODO: remove this hack
  yield call(toastr.error, errorTitle, message)
}

function* processError(resp: IApiResponse, message: string) {
  yield put(getError(resp)) // for SSR HTTP codes
  // TODO: use modern js syntax
  const userMessage =
    resp.error && resp.error.response && resp.error.response.data && resp.error.response.data.ErrorUserMessage
      ? resp.error.response.data.ErrorUserMessage
      : null
  yield showToast(`${message} ${userMessage || "Пожалуйста, попробуйте повторить действие через 3 минуты."}`)
}

function* fetchAdsPage({ params }: IAdsPageFetchAction) {
  yield put(onAdsPageFetching())

  const state: IAppStore = yield select()
  const resp = yield call(makeApiGetRequest, getSearchApiUrl(params, state), state.ads.session.cookie)
  if (!resp || resp.error) {
    yield* processError(resp, "Не удается загрузить список объявлений.")
  } else {
    const pagination = {
      lastPageNumber: resp.data.LastPageNumber,
      currentPageNumber: params.page,
    }
    yield put(onAdCreatedIDGot(resp.data.CreatedAdID))
    yield put(onAdsPageFetched(getUrlWithParamsWoPage(params), resp.data.Ads.map(parseAd), pagination))
  }
}

function* fetchAdsWatcher() {
  if (__CLIENT__) {
    yield throttle(1000, AdActions.AdsPageFetch, fetchAdsPage)
  } else {
    // Otherwise throttling makes large delays in SSR
    yield takeEvery(AdActions.AdsPageFetch, fetchAdsPage)
  }
}

function* fetchAdsCountersWatcher() {
  yield takeEvery(AdActions.AdsCountersFetch, fetchAdsCounters)
}

function* fetchAdsCounters() {
  const state: IAppStore = yield select()
  const resp = yield call(makeApiGetRequest, "/v1/counters/home", state.ads.session.cookie)
  if (!resp || resp.error) {
    yield* processError(resp, "Не удается загрузить счетчик объявлений.")
  } else {
    yield put(onAdsCountersFetched({ today: resp.data.Today, week: resp.data.Week }))
  }
}

function* fetchAdWatcher() {
  yield takeEvery(AdActions.AdFetch, fetchAd)
}

function* fetchAd({ id }: any) {
  const state: IAppStore = yield select()
  const resp = yield call(makeApiGetRequest, `/v1/posts/${id}`, state.ads.session.cookie)
  if (!resp || resp.error) {
    yield* processError(resp, "Не удается загрузить объявление.")
  } else {
    yield put(onAdCreatedIDGot(resp.data.CreatedAdID))
    yield put(onAdFetched(parseAd(resp.data.Ad)))
  }
}

function* editOrCreateAdWatcher() {
  yield takeEvery(AdActions.AdEditSubmitted, editOrCreateAd)
}

const makeAdModificationRequest = (ad: IModifyApiAd, { ads }: IAppStore): any => {
  const data = new FormData()
  if (ad.MainImage) {
    if (ad.MainImage.WasAlreadyUploadedToServer) {
      data.append("MainImage", JSON.stringify(ad.MainImage))
    } else {
      data.append("MainImage", ad.MainImage.File, ad.MainImage.File.name)
    }
  }
  if (ad.AdditionalImages) {
    ad.AdditionalImages.forEach((image: IModifyApiImage, i: number) => {
      if (image.WasAlreadyUploadedToServer) {
        data.append(`AdditionalImages[${i}]`, JSON.stringify(image))
      } else {
        data.append(`AdditionalImages[${i}]`, image.File, image.File.name)
      }
    })
  }

  for (const key in ad) {
    // TODO: rewrite this hacky code, bugs occurred here 3+ times.
    if (
      key === "MainImage" ||
      key === "AdditionalImages" ||
      key === "Location" ||
      key === "Images" ||
      key === "FinishedAt" ||
      key === "FinishedReason" ||
      key === `ClicksCount` ||
      key === `InListViewsCount` ||
      key === `NearestPlaceName`
    ) {
      continue
    }

    const anyAd = ad as any
    data.append(key, anyAd[key])
  }

  if (ads.edit && ads.edit.place) {
    const p = ads.edit.place
    if (p.description) {
      data.delete("PlaceDescription")
      data.append("PlaceDescription", p.description)
    }
    if (p.location) {
      data.append("Location.Lat", p.location.lat.toString())
      data.append("Location.Lon", p.location.lng.toString())
    } else if (ad.Location) {
      data.append("Location.Lat", ad.Location.lat.toString())
      data.append("Location.Lon", ad.Location.lon.toString())
    }
  }

  return data
}

interface IReducerDataWithModifyApiAd {
  type: any
  ad: IModifyApiAd
}

function* editOrCreateAd({ ad }: IReducerDataWithModifyApiAd) {
  const state: IAppStore = yield select()
  if (state.ads.edit.currentAd) {
    console.info("ad is edited, call edition")
    yield* editAd(state.ads.edit.currentAd, ad, state)
  } else {
    yield* createAd(ad, state)
  }
}

const buildAdEventProps = (fetchedAd: IApiAd): object => {
  const props: any = {
    // naming of props here is consistent with AdsIndex event props
    adId: fetchedAd.ID.toString(),
    category: fetchedAd.CategoryName || "",
    animalType: fetchedAd.AnimalTypeName || "",
    place: fetchedAd.NearestPlaceName || "",
    breed: fetchedAd.Breed || "",
    imageCount: (fetchedAd.ImagesMetadataV1 || []).length,
  }
  if (fetchedAd.Location && fetchedAd.Location.lat && fetchedAd.Location.lon) {
    props.latitude = fetchedAd.Location.lat
    props.longitude = fetchedAd.Location.lon
  }
  return props
}

function* createAd(ad: IModifyApiAd, state: IAppStore): any {
  const resp = yield call(makeApiPostRequest, `/v1/posts`, makeAdModificationRequest(ad, state), state.ads.session.cookie)
  if (!resp || resp.error) {
    yield put(onAdEditFailed())
    yield* processError(resp, "Не удалось создать объявление.")
  } else {
    const fetchedAd: IApiAd = resp.data
    yield put(onAdCreatedIDGot(fetchedAd.ID))
    yield put(onAdCreated())
    yield put(onAdFetchedForEdition(fetchedAd))
    yield put(onAdEditStepChanged(2)) // go to step 3
    yield call(reachGoal, "AD_CREATE", "STEP3")
    if (__CLIENT__) {
      yield call(sendAmplitudeEvent, "create_ad", buildAdEventProps(fetchedAd))
    }
    yield put(push(`/ads/${fetchedAd.ID}/edit?ad_created=1`))

    yield put(toggle(toastToggleKey, true)) // lazy-render toast component
    const { toastr } = yield import("react-redux-toastr")
    yield delay(1000) // time to lazy-load toast component, TODO: remove this hack
    yield call(toastr.success, successTitle, "Поздравляем! Объявление успешно создано!")
  }
}

function* editAd(currentAd: IModifyApiAd, newAd: IModifyApiAd, state: IAppStore): any {
  console.info("editAd: making put request...")
  const resp = yield call(makeApiPutRequest, `/v1/posts/${currentAd.ID}`, makeAdModificationRequest(newAd, state), state.ads.session.cookie)
  if (!resp || resp.error) {
    yield put(onAdEditFailed())
    yield* processError(resp, "Не удалось отредактировать объявление.")
  } else {
    const fetchedAd: IApiAd = resp.data
    yield put(onAdFetched(parseAd(fetchedAd)))
    yield put(push(`/ads/${currentAd.ID}?ad_edited=1`))
    yield put(onAdCreated())
    if (__CLIENT__) {
      yield call(sendAmplitudeEvent, "edit_ad", buildAdEventProps(fetchedAd))
    }
  }
  console.info("editAd: finished")
}

function* incrementCounters({ ids, counterType }: any) {
  const resp = yield call(makeApiPostRequest, `/v1/posts/counters/${counterType}`, { ids })
  if (!resp || resp.error) {
    console.warn("can't update view counters:", ids)
  }
}

function* incrementCountersWatcher() {
  yield takeEvery(AdActions.AdsIncrementCounter, incrementCounters)
}

function* fetchAdForEdition({ id }: any) {
  const state: IAppStore = yield select()
  const resp = yield call(makeApiGetRequest, `/v1/posts/${id}`, state.ads.session.cookie)
  if (!resp || resp.error) {
    yield* processError(resp, "Не удается загрузить объявление.")
  } else {
    if (resp.data.CreatedAdID !== id) {
      console.warn("attempt to edit not my ad: %s !== %s", resp.data.CreatedAdID, id)
      yield put(notFound())
    } else {
      yield put(onAdFetchedForEdition(resp.data.Ad))
    }
  }
}

function* finishAd({ adId }: any) {
  console.info("finishAd: making put request...")
  const state: IAppStore = yield select()
  const resp = yield call(makeApiPutRequest, `/v1/posts/${adId}/finish`, {}, state.ads.session.cookie)
  if (!resp || resp.error) {
    yield* processError(resp, "Не удалось закрыть объявление.")
    yield put(onAdFinishComplete())
    return
  }

  const fetchedAd: IApiAd = resp.data.Ad
  const ad = parseAd(fetchedAd)
  yield put(onAdFetched(ad))
  yield put(onAdFinishComplete())
  yield call(sendAmplitudeEvent, "finish_ad", buildAdEventProps(fetchedAd))
  console.info("finishAd: finished")
}

function* fetchAdForEditionWatcher() {
  yield takeEvery(AdActions.AdFetchForEdition, fetchAdForEdition)
}

function* finishAdWatcher() {
  yield takeEvery(AdActions.AdFinish, finishAd)
}

export function getAdsWatchers(): Array<ForkEffect<void>> {
  return [
    fork(fetchAdsWatcher),
    fork(fetchAdsCountersWatcher),
    fork(fetchAdWatcher),
    fork(editOrCreateAdWatcher),
    fork(incrementCountersWatcher),
    fork(fetchAdForEditionWatcher),
    fork(finishAdWatcher),
  ]
}
