import { combineReducers, Action } from "redux"
import { LOCATION_CHANGE } from "connected-react-router"
import { AdActions } from "./ads.actions"
import { IPaginationParams } from "../../components/lib/Pagination"
import { urlParamNumber, urlParamStr, urlParam, urlPart } from "../utils/url"
import { IPetProperty } from "../../components/partials/PetCard"
import { IApiAd, makeModifyApiAd, IModifyApiAd } from "./api_ad"

export interface IImageMetadata {
  id: string
  width?: number
  height?: number
  lqipBase64?: string
  lqipType?: string
  version?: number // used only for cache busting, local field, not retrieve from API
}

export interface IAd {
  id: number
  source: string
  title: string
  description: string
  category: string
  animalType: string
  createdAt: string
  inListViewsCount: number
  viewsCount: number
  mainImage?: IImageMetadata
  additionalImages: IImageMetadata[]
  location: {
    lat: number
    lon: number
  }

  name: string
  gender: string
  color: string
  signs: string
  reward: string
  phone: string
  breed: string
  placeDescription: string

  nearestPlaceName?: string
  finishedAt?: string
  finishedReason?: string
}

interface IAdsStorage {
  [adId: number]: IAd
}

interface IAdsPages {
  [pageNumber: number]: number[]
}

interface IAdsIndex {
  [location: string]: IAdsPages
}

export interface IAdsPageParams {
  place: string
  category: string
  animalType: string
  page: number
  query?: string
  searchRadius?: number
  finishedFilter: string
  sourceFilter?: string
}

interface ISession {
  createdAdId?: number
  cookie?: string
}

export interface IAdsCounters {
  today: number
  week: number
}

export interface IAdEditPlaceState {
  location: {
    lat: number
    lng: number
  }
  description: string
}

interface IAdEditState {
  place?: IAdEditPlaceState
  currentStep?: number
  isLoading?: boolean
  currentAd?: IModifyApiAd
}

export interface IAdsStore {
  storage: IAdsStorage
  index: IAdsIndex
  pagination: IPaginationParams
  isFetching?: boolean
  session?: ISession
  counters?: IAdsCounters
  edit?: IAdEditState
  isNoCache?: boolean
  areFiltersCollapsed?: boolean
  selectedImageIndex?: number // index of currently open image modal for react-images
  isAdFinishing?: boolean
}

const storage = (state: IAdsStorage = null, action: any): IAdsStorage => {
  switch (action.type) {
    case AdActions.AdFetched:
      const curAd: IAd = action.ad
      return { ...state, [curAd.id]: curAd }
    case AdActions.AdsPageFetched:
      const ads: IAd[] = action.ads
      const adsMap: IAdsStorage = {}
      for (const ad of ads) {
        adsMap[ad.id] = ad
      }
      return { ...state, ...adsMap }
    default:
      return state
  }
}

const index = (state: IAdsIndex = {}, action: any): IAdsIndex => {
  switch (action.type) {
    case AdActions.AdsPageFetched:
      const ads: IAd[] = action.ads
      const adsIds = ads.map((ad) => ad.id)
      const curPagination: IPaginationParams = action.pagination
      const location: string = action.location
      return {
        ...state,
        [location]: { ...state[location], [curPagination.currentPageNumber]: adsIds },
      }
    default:
      return state
  }
}

const pagination = (state: IPaginationParams = null, action: any): IPaginationParams => {
  switch (action.type) {
    case AdActions.AdsPageFetched:
      const curPagination: IPaginationParams = action.pagination
      return curPagination
    default:
      return state
  }
}

const isFetching = (state = false, action: any): boolean => {
  switch (action.type) {
    case AdActions.AdsPageIsFetching:
      return true
    case AdActions.AdsPageFetched:
    case AdActions.AdsPageFetchingFailed:
      return false
    default:
      return state
  }
}

export const parseParamsFromUrl = (path: string, search: string): IAdsPageParams => {
  return {
    place: urlPart(0, path) || "Все города",
    category: urlPart(1, path),
    animalType: urlPart(2, path),
    page: urlParamNumber("page", search) || 1,
    query: urlParam("q", search) || "",
    searchRadius: urlParamNumber("search_radius", search) || 20,
    finishedFilter: urlParamStr("finished", search) || `no`,
    sourceFilter: urlParamStr("source", search),
  }
}

const session = (state: ISession = { createdAdId: null }, action: any): ISession => {
  switch (action.type) {
    case AdActions.AdCreatedIDGot:
      return {
        createdAdId: action.id,
      }
    default:
      return state
  }
}

const counters = (state: IAdsCounters = null, action: any): IAdsCounters => {
  switch (action.type) {
    case AdActions.AdsCountersFetched:
      return action.counters
    default:
      return state
  }
}

const edit = (state: IAdEditState = { place: null, currentStep: 1 }, action: any): IAdEditState => {
  switch (action.type) {
    case AdActions.AdEditPlaceChanged:
      const p = action.place
      return {
        ...state,
        place: {
          location: p.location,
          description: p.description,
          searchBox: p.searchBox,
        },
      }
    case AdActions.AdEditStepChanged:
      const currentStep = (state.currentStep || 1) + action.diff
      return { ...state, currentStep }
    case AdActions.AdEditSubmitted:
      return { ...state, isLoading: true }
    case AdActions.AdEditFailed:
      return { ...state, isLoading: false }
    case AdActions.AdCreated:
      return {}
    case AdActions.AdFetchedForEdition:
      return { ...state, currentAd: makeModifyApiAd(action.ad) }
    default:
      return state
  }
}

const isNoCache = (state = false, action: any): boolean => {
  switch (action.type) {
    default:
      return state
  }
}

const areFiltersCollapsed = (state = true, action: any): boolean => {
  switch (action.type) {
    case AdActions.InverseFiltersCollapsion:
      return !state
    default:
      return state
  }
}

const selectedImageIndex = (state: any = null, action: any): number => {
  switch (action.type) {
    case AdActions.SelectImage:
      return action.index
    default:
      return state
  }
}

const isAdFinishing = (state: boolean = false, action: any): boolean => {
  switch (action.type) {
    case AdActions.AdFinish:
      return true
    case AdActions.AdFinishComplete:
      return false
    default:
      return state
  }
}

export const adsReducer = combineReducers<IAdsStore>({
  storage,
  index,
  isFetching,
  pagination,
  session,
  counters,
  edit,
  isNoCache,
  areFiltersCollapsed,
  selectedImageIndex,
  isAdFinishing,
})

export const getInitalClientAdsState = (): IAdsStore => {
  return {
    storage: {},
    index: {},
    pagination: {
      currentPageNumber: 1,
      lastPageNumber: null,
    },
    session: {
      createdAdId: null,
    },
  }
}

export const getInitalServerAdsState = (path: string, search: string, cookie: string): IAdsStore => {
  return {
    storage: {},
    index: {},
    pagination: {
      currentPageNumber: 1,
      lastPageNumber: null,
    },
    session: {
      createdAdId: null,
      cookie,
    },
    isNoCache: urlParamNumber("nocache", search) === 1,
  }
}
