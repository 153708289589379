import { combineReducers } from "redux"
import { ResultActions } from "./result.actions"

export enum ResultCodes {
  Ok = 200,
  InternalError = 500,
  NotFound = 404,
}

export interface IResultStore {
  code: ResultCodes
}

const code = (state: ResultCodes = ResultCodes.Ok, action: any): ResultCodes => {
  switch (action.type) {
    case ResultActions.InternalErrorOccured:
      return ResultCodes.InternalError
    case ResultActions.NotFoundOccured:
      return ResultCodes.NotFound
    default:
      return state
  }
}

export default combineReducers<IResultStore>({
  code,
})
