import { hot } from "react-hot-loader/root"
import React from "react"
import ReactDOM from "react-dom"
import { loadableReady } from "@loadable/component"
import { getCLS, getFCP, getFID, getLCP, Metric } from "web-vitals"

import configureStore, { history, ConfiguredStore } from "./store/configure"

import { getInitalClientAdsState } from "./modules/ads/ads.reducers"
import { IAppStore } from "./reducers"
import rootSaga from "./sagas"
import { reachGoal } from "./modules/utils/events"
import Root from "./ClientRoot"

export default class ClientApp {
  private store: ConfiguredStore

  private sendStatHit(action: string) {
    if (__DEV__) {
      return
    }

    const w = window as any
    if (w.yaCounter42654659 !== undefined) {
      w.yaCounter42654659.hit(window.location.href)
    } else {
      console.warn("history.listen: w.yaCounter42654659 is undefined, can't send hit")
    }
    if (w.ga !== undefined) {
      w.ga("send", "pageview")
    } else {
      console.warn("history.listen: w.ga is undefined, can't send hit")
    }

    if (w.yaCounter42654659 !== undefined && w.ga !== undefined) {
      console.info("history.listen: sent ya+ga hits: window.location.href is %s. History args: action is %s", window.location.href, action)
    }
  }

  private sendToGoogleAnalytics = ({ name, delta, id }: any) => {
    const w = window as any
    if (!w.ga) {
      return
    }

    w.ga("send", "event", {
      eventCategory: "Web Vitals",
      eventAction: name,
      // Google Analytics metrics must be integers, so the value is rounded.
      // For CLS the value is first multiplied by 1000 for greater precision
      // (note: increase the multiplier for greater precision if needed).
      eventValue: Math.round(name === "CLS" ? delta * 1000 : delta),
      // The `id` value will be unique to the current page load. When sending
      // multiple values from the same page (e.g. for CLS), Google Analytics can
      // compute a total by grouping on this ID (note: requires `eventLabel` to
      // be a dimension in your report).
      eventLabel: id,
      // Use a non-interaction event to avoid affecting bounce rate.
      nonInteraction: true,
    })
  }

  private reportPerfMetric = (metric: Metric): void => {
    console.info(`Got perfomance metric ${metric.name}`, metric)
    this.wrapSafe(async () => {
      this.sendToGoogleAnalytics(metric)
    })
  }

  private onReadyToRender = async (): Promise<void> => {
    await this.wrapSafe(async () => {
      const stateFromServer = (window as any).__INITIAL_STATE__
      const initialState: IAppStore = stateFromServer
        ? stateFromServer
        : {
            ads: getInitalClientAdsState(),
          }
      this.store = await configureStore(initialState)

      history.listen((location, action) => {
        this.sendStatHit(action)
        if (action === "PUSH") {
          window.scrollTo(0, 0)
        }
      })

      this.store.runSaga(rootSaga)

      console.info(`Rendering client app...`)
      ReactDOM.hydrate(<Root store={this.store} history={history} />, document.getElementById("react-app"))
    })
    this.wrapSafe(async () => {
      getCLS(this.reportPerfMetric)
      getFCP(this.reportPerfMetric)
      getFID(this.reportPerfMetric)
      getLCP(this.reportPerfMetric)
    })
  }

  public rerender() {
    console.info(`Re-rendering client app...`)
    ReactDOM.render(<Root store={this.store} history={history} />, document.getElementById("react-app"))
  }

  private async wrapSafe(f: () => Promise<void>) {
    try {
      await f()
    } catch (e) {
      console.error("exception occured: %o", e)
      try {
        this.onError("wrapSafe(error)", "", 0, 0, e)
      } catch (e1) {
        console.error("nested exception: %o", e1)
      }
    }
  }

  public run(): void {
    loadableReady(this.onReadyToRender)
    window.onerror = this.onError
    window.addEventListener("error", async (e) => {
      await this.onError("addEventListener(error)", "", 0, 0, e)
    })
  }

  private onError = async (msg: string, url: string, lineNo: number, columnNo: number, error: any): Promise<boolean> => {
    try {
      const st = await import("stacktrace-js")
      st.fromError(error)
        .then((stackframes) => {
          const stack = stackframes.map((sf) => sf.toString()).join("\n")
          this.sendError({ msg, url, lineNo, columnNo, error, stack })
        })
        .catch((err) => {
          this.sendError({ msg: "error in stacktrace", sourceMsg: msg, url, lineNo, columnNo, sourceError: error, err })
        })
    } catch (e) {
      this.sendError({ msg: "exception in stacktrace", e })
    }

    return false
  }

  private sendError(data: object) {
    console.error("sendError:", data)
    try {
      // TODO
      // $.post(`//api.${window.location.host}/v1/log`, {
      //   data,
      // })
    } catch (e) {
      console.error("sendError send data exception:", e)
    }
  }
}

console.info(`Starting client app...`)
const app = new ClientApp()
app.run()
if (module.hot) {
  console.info(`Setup hot replacement for client app...`)
  module.hot.accept("./routes/routes", () => {
    app.rerender()
  })
}
