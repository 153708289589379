import { PlaceActions, onNearPlacesFetched } from "./places.actions"
import { makeApiGetRequest } from "../api"
import { put, takeEvery, call } from "redux-saga/effects"

function* fetchNearPlaces() {
  const resp = yield call(makeApiGetRequest, "/v1/places/near")

  if (resp && !resp.error) {
    yield put(onNearPlacesFetched(resp.data.NearPlaces))
  }
}

export function* fetchNearPlacesWatcher() {
  yield takeEvery(PlaceActions.NearFetch, fetchNearPlaces)
}
