export enum PlaceActions {
  NearFetch = "@@FOUNDPETS/PLACES/NEAR/FETCH",
  NearFetched = "@@FOUNDPETS/PLACES/NEAR/FETCHED",
}

export const onNearPlacesFetched = (nearPlaces: string[]) => ({
  type: PlaceActions.NearFetched,
  nearPlaces,
})

export const nearPlacesFetch = () => ({
  type: PlaceActions.NearFetch,
})
