/** @jsx jsx */
import { jsx, css, keyframes } from "@emotion/core"
import { FaSpinner } from "react-icons/fa"

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% {  transform: rotate(359deg); }
`

export default () => (
  <FaSpinner
    css={css`
      animation: ${spin} 2s linear infinite;
    `}
    size="3em"
    color="#4DAF7C"
  />
)
