import * as queryString from "query-string"

function addQs(path: string, search: string): string {
  const parsed = queryString.parse(search)
  parsed.page = undefined
  const qs = queryString.stringify(parsed)
  return `${path}${qs !== "" ? "?" : ""}${qs}`
}

export function urlParam(name: string, search: string): string {
  const parsed = queryString.parse(search)
  return parsed[name]
}

export function urlParamNumber(name: string, search: string): number {
  const parsed = queryString.parse(search)
  return Number(parsed[name])
}

export function urlParamStr(name: string, search: string): string {
  const parsed = queryString.parse(search)
  return parsed[name]
}

function urlParts(path: string): string[] {
  return path
    .split("/")
    .filter((p) => p)
    .map(decodeURIComponent)
}

export function changeUrlElem(i: number, v: string, path: string, search: string): string {
  const uriParts = urlParts(path)
  uriParts[i] = v
  const ret = addQs("/" + uriParts.join("/"), search)
  return ret
}

export function changeParamInUrl(name: string, value: string, path: string, search: string): string {
  const parsed = queryString.parse(search)
  parsed[name] = value
  const qs = queryString.stringify(parsed)
  return `${decodeURIComponent(path)}${qs !== "" ? "?" : ""}${qs}`
}

export function changeParamsInUrl(params: object, path: string, search: string): string {
  const parsed = queryString.parse(search)
  for (const p of Object.keys(params)) {
    parsed[p] = (params as any)[p]
  }
  const qs = queryString.stringify(parsed)
  return `${decodeURIComponent(path)}${qs !== "" ? "?" : ""}${qs}`
}

export function urlPart(i: number, path: string): string {
  return urlParts(path)[i]
}
