export enum ResultActions {
  InternalErrorOccured = "@@FOUNDPETS/RESULT/INTERNAL_ERROR_OCCURED",
  NotFoundOccured = "@@FOUNDPETS/RESULT/NOT_FOUND_OCCURED",
}

export const internalError = () => ({
  type: ResultActions.InternalErrorOccured,
})

export const notFound = () => ({
  type: ResultActions.NotFoundOccured,
})
