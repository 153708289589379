import { IPaginationParams } from "../../components/lib/Pagination"
import { Action } from "redux"
import { IAdsPageParams, IAd, IAdsCounters, IAdEditPlaceState } from "./ads.reducers"
import { IApiAd, IModifyApiAd } from "./api_ad"

export enum AdActions {
  AdsPageFetched = "ADS_PAGE/FETCHED",
  AdsPageIsFetching = "ADS_PAGE/IS_FETCHING",
  AdsPageFetchingFailed = "ADS_PAGE/FETCHING_FAILED",
  AdsPageFetch = "ADS_PAGE/FETCH",

  AdsCountersFetch = "@foundpets/ads/counters/fetch",
  AdsCountersFetched = "@foundpets/ads/counters/fetched",

  AdFetch = "@foundpets/ads/ad/fetch",
  AdFetched = "@foundpets/ads/ad/fetched",

  AdEditPlaceChanged = "@foundpets/ads/edit/place/fetched",
  AdEditStepChanged = "@foundpets/ads/edit/step/fetched",
  AdEditSubmitted = "@foundpets/ads/edit/submitted",
  AdEditFailed = "@foundpets/ads/edit/failed",

  AdCreated = "@foundpets/ads/created",
  AdCreatedIDGot = "@foundpets/ads/created/id/got",
  AdsIncrementCounter = "@foundpets/ads/counters/increment",

  AdFetchForEdition = "@foundpets/ads/edit/fetch",
  AdFetchedForEdition = "@foundpets/ads/edit/fetched",

  InverseFiltersCollapsion = "@foundpets/ads/filters/collapsion/inverse",
  SelectImage = "@foundpets/ads/images/select",

  AdFinish = "@foundpets/ads/finish",
  AdFinishComplete = "@foundpets/ads/finish_complete",
}

export const adFinish = (adId: number) => ({ type: AdActions.AdFinish, adId })
export const onAdFinishComplete = () => ({ type: AdActions.AdFinishComplete })

export const inverseFiltersCollapsion = () => ({ type: AdActions.InverseFiltersCollapsion })

export const selectImage = (index: number) => ({ type: AdActions.SelectImage, index })

export const onAdEditFailed = () => ({ type: AdActions.AdEditFailed })

export const onAdFetchedForEdition = (ad: IApiAd) => ({
  type: AdActions.AdFetchedForEdition,
  ad,
})

export const adFetchForEdition = (id: number) => ({
  type: AdActions.AdFetchForEdition,
  id,
})

export const adsIncrementCounter = (ids: number[], counterType: string) => ({
  type: AdActions.AdsIncrementCounter,
  ids,
  counterType,
})

export const onAdCreatedIDGot = (id: number) => ({
  type: AdActions.AdCreatedIDGot,
  id,
})

export const onAdCreated = () => ({
  type: AdActions.AdCreated,
})

export const onAdEditSubmitted = (ad: IModifyApiAd) => ({
  type: AdActions.AdEditSubmitted,
  ad,
})

export const onAdEditStepChanged = (diff: number) => ({
  type: AdActions.AdEditStepChanged,
  diff,
})

export const onAdEditPlaceChanged = (place: IAdEditPlaceState) => ({
  type: AdActions.AdEditPlaceChanged,
  place,
})

export const onAdFetched = (ad: IAd) => ({
  type: AdActions.AdFetched,
  ad,
})

export const adFetch = (id: number) => ({
  type: AdActions.AdFetch,
  id,
})

export const onAdsPageFetched = (location: string, ads: IAd[], pagination: IPaginationParams) => ({
  type: AdActions.AdsPageFetched,
  ads,
  location,
  pagination,
})

export const onAdsPageFetching = () => ({
  type: AdActions.AdsPageIsFetching,
})

export interface IAdsPageFetchAction extends Action {
  params: IAdsPageParams
}

export const adsPageFetch = (params: IAdsPageParams): IAdsPageFetchAction => ({
  type: AdActions.AdsPageFetch,
  params,
})

export const adsCountersFetch = () => ({
  type: AdActions.AdsCountersFetch,
})

export const onAdsCountersFetched = (counters: IAdsCounters) => ({
  type: AdActions.AdsCountersFetched,
  counters,
})
