import { hot } from "react-hot-loader"
import React from "react"
import { History } from "history"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import { ConfiguredStore } from "./store/configure"
import Routes from "./routes/routes"

interface IAppProps {
  store: ConfiguredStore
  history: History<{}>
}

const Root = (props: IAppProps) => (
  <Provider store={props.store}>
    <ConnectedRouter history={props.history}>
      <Routes />
    </ConnectedRouter>
  </Provider>
)

export default hot(module)(Root)
